import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Layout from "../components/layout";
import Button from "../components/button";
import ButtonRow from "../components/button_row";

import center from "../images/center.svg";
import tools from "../images/tools.svg";
import microscope from "../images/microscope.svg";
import fist from "../images/fist.svg";

const query = graphql
`
query index {
  allMarkdownRemark(filter: {frontmatter: {path: {eq: "/"}}}) {
    edges {
      node {
        id
        frontmatter {
          path
        }
        excerpt
        html
        rawMarkdownBody
      }
    }
  }
}
`;


const IndexPage = props => (
  <Layout>
    <div dangerouslySetInnerHTML={{ __html: props.html}}/>
    <ButtonRow>
      <Button
        img={fist}
        flavorText="Na ulice!"
        link="/na_ulice"
      />

      <Button
        img={tools}
        flavorText="Orodjarna"
        link="/orodjarna"
      />

      <Button
        img={microscope}
        flavorText="Analize"
        link="/analize"
      />

      <Button
        img={center}
        flavorText="Predlogi ukrepov"
        link="/predlogi"
      />
    </ButtonRow>
  </Layout>
);

export default () => (
    <StaticQuery
        query={query}
        render={ data => (
          <IndexPage html={data.allMarkdownRemark.edges[0].node.html}/>
        )}
    />
);
